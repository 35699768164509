// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #181818; /* Example color, adjust as needed */
}

.logo {
    font-size: 1.5em;
    color: white;
    text-decoration: none;
}

.header-right {
    display: flex;
    align-items: center;
}

.header-right a {
    margin-right: 20px;
    color: white;
    text-decoration: none;
    padding: 8px 16px;
}

.header-right a.active {
    background-color: #76a245; /* Active link background color */
    border-radius: 4px;
}

.header-right a:hover {
    background-color: #76a245; /* Hover effect for links */
    border-radius: 4px;
}

.logout-button {
    padding: 8px 16px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.logout-button:hover {
    background-color: darkred; /* Darker red on hover */
}

/* Media query to hide navigation links on small screens */
@media (max-width: 600px) {
    .header-right a {
        display: none;
    }

    .logo {
        font-size: 1.2em;
    }

    .logout-button {
        padding: 6px 12px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Header.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB,EAAE,oCAAoC;AACnE;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB,EAAE,iCAAiC;IAC5D,kBAAkB;AACtB;;AAEA;IACI,yBAAyB,EAAE,2BAA2B;IACtD,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB,EAAE,wBAAwB;AACvD;;AAEA,0DAA0D;AAC1D;IACI;QACI,aAAa;IACjB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["/* Header.css */\n.header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 20px;\n    background-color: #181818; /* Example color, adjust as needed */\n}\n\n.logo {\n    font-size: 1.5em;\n    color: white;\n    text-decoration: none;\n}\n\n.header-right {\n    display: flex;\n    align-items: center;\n}\n\n.header-right a {\n    margin-right: 20px;\n    color: white;\n    text-decoration: none;\n    padding: 8px 16px;\n}\n\n.header-right a.active {\n    background-color: #76a245; /* Active link background color */\n    border-radius: 4px;\n}\n\n.header-right a:hover {\n    background-color: #76a245; /* Hover effect for links */\n    border-radius: 4px;\n}\n\n.logout-button {\n    padding: 8px 16px;\n    background-color: red;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.logout-button:hover {\n    background-color: darkred; /* Darker red on hover */\n}\n\n/* Media query to hide navigation links on small screens */\n@media (max-width: 600px) {\n    .header-right a {\n        display: none;\n    }\n\n    .logo {\n        font-size: 1.2em;\n    }\n\n    .logout-button {\n        padding: 6px 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
