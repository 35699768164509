import React, { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './ComputedShoppingListPage.css'; // Add your CSS file here

function ComputedShoppingListPage() {
  const [shoppingList, setShoppingList] = useState([]);
  const [superiorInventory, setSuperiorInventory] = useState([]);
  const [rdInventory, setRdInventory] = useState([]);
  const [computedList, setComputedList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const shoppingListSnapshot = await getDocs(collection(db, 'shopping_list'));
      const superiorSnapshot = await getDocs(collection(db, 'superior_inventory'));
      const rdSnapshot = await getDocs(collection(db, 'rd_inventory'));

      const shoppingListData = shoppingListSnapshot.docs.map(doc => doc.data());
      const superiorData = superiorSnapshot.docs.map(doc => doc.data());
      const rdData = rdSnapshot.docs.map(doc => doc.data());

      setShoppingList(shoppingListData);
      setSuperiorInventory(superiorData);
      setRdInventory(rdData);

      const computed = computeShoppingList(shoppingListData, superiorData, rdData);
      setComputedList(computed);
      setLoading(false);
    };

    fetchData();
  }, []);

  const computeShoppingList = (shoppingList, superiorInventory, rdInventory) => {
    return shoppingList.map((shoppingItem) => {
      const superiorMatches = superiorInventory.filter(item =>
        shoppingItem.itemName.toLowerCase().includes(item.name.toLowerCase())
      );
      const rdMatches = rdInventory.filter(item =>
        shoppingItem.itemName.toLowerCase().includes(item.name.toLowerCase())
      );

      return {
        shoppingItemName: shoppingItem.itemName,
        superior: superiorMatches.length ? superiorMatches : null,
        rd: rdMatches.length ? rdMatches : null,
      };
    });
  };

  if (loading) {
    return (
      <div className="loading-container">
        <h2>Computing Shopping List...</h2>
        <div className="loading-bar"></div>
      </div>
    );
  }

  return (
    <div className="computed-shopping-list-page">
      <h1>Computed Shopping List</h1>
      <button onClick={() => navigate('/shopping-list')} className="back-button">
        Back to Shopping List
      </button>
      <div className="computed-list">
        {computedList.map((item, index) => (
          <div key={index} className="computed-item">
            <h2>{item.shoppingItemName}</h2>
            {item.superior && (
              <div className="sublists">
                <h3>Superior:</h3>
                {item.superior.map((superiorItem, idx) => (
                  <p key={idx}>- {superiorItem.name} | ${superiorItem.price}</p>
                ))}
              </div>
            )}
            {item.rd && (
              <div className="sublists">
                <h3>RD:</h3>
                {item.rd.map((rdItem, idx) => (
                  <p key={idx}>- {rdItem.name} | ${rdItem.price}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ComputedShoppingListPage;
