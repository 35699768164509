import React, { useState } from 'react';
import { storage, auth, db } from './firebase-config'; // Ensure this path is correct
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import './ReceiptSubmission.css';

function ReceiptSubmission() {
    const [image, setImage] = useState(null);
    const [amount, setAmount] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [reimbursement, setReimbursement] = useState(false);
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [customer, setCustomer] = useState('');
    const [otherCustomer, setOtherCustomer] = useState('');

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);

        if (!image) {
            setError('Please upload an image.');
            return;
        }

        const user = auth.currentUser;
        if (!user) {
            setError('No user logged in.');
            return;
        }

        const storageRef = ref(storage, `receipts/${Date.now()}-${image.name}`);
        try {
            await uploadBytes(storageRef, image);
            const imageURL = await getDownloadURL(storageRef);

            await addDoc(collection(db, 'receipts'), {
                timestamp: Timestamp.now(),
                user: user.email,
                amount: parseFloat(amount),
                transactionType: paymentType,
                reimbursement,
                description,
                imageURL,
                customer: paymentType === 'Restaurant Meat' ? (customer === 'other' ? otherCustomer : customer) : null,
            });

            setSuccess(true);
            setImage(null);
            setAmount('');
            setPaymentType('');
            setReimbursement(false);
            setDescription('');
            setCustomer('');
            setOtherCustomer('');
        } catch (err) {
            console.error('Error uploading image or saving data: ', err);
            setError('Error uploading image or saving data.');
        }
    };

    return (
        <div className="receipt-submission-container">
            <h2>Submit Receipt</h2>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">Upload successful!</p>}
            {!success && (
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>Upload Image</label>
                        <input type="file" onChange={handleImageChange} required />
                    </div>
                    <div className="input-group">
                        <label>Total Amount</label>
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label>Receipt/Invoice Type</label>
                        <select value={paymentType} onChange={(e) => setPaymentType(e.target.value)} required>
                            <option value="">Select Receipt/Invoice Type</option>
                            <option value="Store Cash">Store Cash</option>
                            <option value="Company Card">Company Card</option>
                            <option value="Personal Payment">Personal Payment</option>
                            <option value="Restaurant Meat">Restaurant Meat</option>
                        </select>
                    </div>
                    {paymentType === 'Personal Payment' && (
                        <div className="input-group">
                            <label>Reimbursement Needed</label>
                            <input
                                type="checkbox"
                                checked={reimbursement}
                                onChange={(e) => setReimbursement(e.target.checked)}
                            />
                        </div>
                    )}
                    <div className="input-group">
                        <label>Description (optional)</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                </form>
            )}
            {success && (
                <button className="submit-button" onClick={() => setSuccess(false)}>Upload Another</button>
            )}
        </div>
    );
}

export default ReceiptSubmission;
