// src/MainPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MainPage.css'; // Ensure the path is correct

function MainPage() {
    const navigate = useNavigate();

    return (
        <div className="main-container">
            <h1>Welcome to H2Fresh Dashboard</h1>
            <div className="section">
                <h2>Employee Actions</h2>
                <button className="button" onClick={() => navigate('/submit-receipt')}>Upload a Receipt</button>
                <button className="button" onClick={() => navigate('/shopping-list')} style={{ marginTop: '10px' }}>Shopping List</button>
                <button className="button" onClick={() => navigate('/meat-market')} style={{ marginTop: '10px' }}>Meat Market Items</button>
            </div>
            <div className="section manager-section">
                <h2>Manager Dashboard</h2>
                <button className="button manager-button" onClick={() => navigate('/rest-meat-dashboard')} style={{ marginTop: '10px' }}>Restaurant Meat Dashboard</button>
                <button className="button manager-button" onClick={() => navigate('/receipt-dashboard')} style={{ marginTop: '10px' }}>Receipt Dashboard</button>
                <button className="button manager-button" onClick={() => navigate('/storage')} style={{ marginTop: '10px' }}>Go to Storage</button>
                <button className="button manager-button" onClick={() => navigate('/in-store')} style={{ marginTop: '10px' }}>Go to In-Store</button>
            </div>
        </div>
    );
}

export default MainPage;
