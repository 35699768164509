import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './Header';
import Login from './Login';
import Storage from './StoragePage';
import InStore from './InventoryPage';
import Home from './MainPage';
import ReceiptSubmission from './ReceiptSubmission';
import ReceiptDashboard from './ReceiptDashboard';
import ShoppingListPage from './ShoppingListPage';
import ComputedShoppingListPage from './ComputedShoppingListPage'; // Import the new component
import MeatMarketPage from './MeatMarketPage';
import MeatOrderDashboard from './MeatOrderDashboard'; // Import the new component
import PrivateRoute from './PrivateRoute';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase-config';
import { getIdTokenResult } from 'firebase/auth';

function App() {
  const [user, loading] = useAuthState(auth);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        try {
          const idTokenResult = await getIdTokenResult(user);
          setRole(idTokenResult.claims.role || 'employee');
        } catch (error) {
          console.error('Error fetching user role:', error);
          setRole(null);
        }
      } else {
        setRole(null);
      }
    };

    fetchUserRole();
  }, [user]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while checking authentication
  }

  return (
    <Router>
      <Header />
      <Routes>
        <Route
          path="/login"
          element={user ? <Navigate to="/" /> : <Login />}
        />
        <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
        <Route
          path="/storage"
          element={
            <PrivateRoute user={user} role={role} allowedRoles={['manager']}>
              <Storage />
            </PrivateRoute>
          }
        />
        <Route
          path="/in-store"
          element={
            <PrivateRoute user={user} role={role} allowedRoles={['manager', 'employee']}>
              <InStore />
            </PrivateRoute>
          }
        />
        <Route
          path="/submit-receipt"
          element={
            <PrivateRoute user={user} role={role} allowedRoles={['manager', 'employee']}>
              <ReceiptSubmission />
            </PrivateRoute>
          }
        />
        <Route
          path="/receipt-dashboard"
          element={
            <PrivateRoute user={user} role={role} allowedRoles={['manager']}>
              <ReceiptDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/shopping-list"
          element={
            <PrivateRoute user={user} role={role} allowedRoles={['manager', 'employee']}>
              <ShoppingListPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/meat-market"
          element={
            <PrivateRoute user={user} role={role} allowedRoles={['manager', 'employee']}>
              <MeatMarketPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/rest-meat-dashboard"
          element={
            <PrivateRoute user={user} role={role} allowedRoles={['manager']}>
              <MeatOrderDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/computed-shopping-list"
          element={
            <PrivateRoute user={user} role={role} allowedRoles={['manager', 'employee']}>
              <ComputedShoppingListPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
