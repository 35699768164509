import React, { useState, useEffect, useCallback } from 'react';
import { db } from './firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import './MeatOrderDashboard.css';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

function MeatOrderDashboard() {
    const [meatOrders, setMeatOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [viewBy, setViewBy] = useState('daily');
    const [weekOptions, setWeekOptions] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState('');

    const fetchMeatOrders = useCallback(async () => {
        try {
            const receiptsCollection = collection(db, 'receipts');
            const receiptsSnapshot = await getDocs(receiptsCollection);
            let meatOrderData = receiptsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            })).filter(receipt => receipt.transactionType === 'Restaurant Meat');

            // Sort by timestamp (most recent first)
            meatOrderData.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

            // Generate week options from the data
            const weeks = new Set();
            meatOrderData.forEach(order => {
                const date = new Date(order.timestamp.seconds * 1000);
                const week = `${date.getFullYear()}-W${Math.ceil((date.getDate() - date.getDay()) / 7)}`;
                weeks.add(week);
            });

            setWeekOptions([...weeks]);
            setMeatOrders(meatOrderData);
            setFilteredOrders(meatOrderData);
        } catch (error) {
            console.error("Error fetching meat orders:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchMeatOrders();
    }, [fetchMeatOrders]);

    const handleViewByChange = (e) => {
        setViewBy(e.target.value);
    };

    const handleWeekFilterChange = (e) => {
        setSelectedWeek(e.target.value);
        filterOrdersByWeek(e.target.value);
    };

    const filterOrdersByWeek = (week) => {
        if (week) {
            const filtered = meatOrders.filter(order => {
                const date = new Date(order.timestamp.seconds * 1000);
                const orderWeek = `${date.getFullYear()}-W${Math.ceil((date.getDate() - date.getDay()) / 7)}`;
                return orderWeek === week;
            });
            setFilteredOrders(filtered);
        } else {
            setFilteredOrders(meatOrders);
        }
    };

    const aggregateData = (orders, viewBy) => {
        const grouped = orders.reduce((acc, order) => {
            const date = new Date(order.timestamp.seconds * 1000);
            const key = viewBy === 'weekly' 
                ? `${date.getFullYear()}-W${Math.ceil((date.getDate() - date.getDay()) / 7)}`
                : date.toISOString().split('T')[0];

            if (!acc[key]) acc[key] = 0;
            acc[key] += order.amount;

            return acc;
        }, {});

        const labels = Object.keys(grouped);
        const data = Object.values(grouped);

        return { labels, data };
    };

    const chartData = aggregateData(filteredOrders, viewBy);

    const totalSpending = filteredOrders.reduce((sum, order) => sum + order.amount, 0);
    const averageSpending = filteredOrders.length ? (totalSpending / filteredOrders.length).toFixed(2) : 0;
    const totalOrders = filteredOrders.length;

    return (
        <div className="meat-order-dashboard-container">
            <h2>Meat Order Dashboard</h2>

            <div className="chart-container">
                <h3>Price vs Day</h3>
                <Line
                    data={{
                        labels: chartData.labels,
                        datasets: [
                            {
                                label: 'Price',
                                data: chartData.data,
                                borderColor: 'rgba(75,192,192,1)',
                                backgroundColor: 'rgba(75,192,192,0.2)',
                            }
                        ]
                    }}
                    options={{
                        maintainAspectRatio: false,
                    }}
                />
            </div>

            <div className="filters">
                <label htmlFor="week-filter-select">Filter by Week: </label>
                <select
                    id="week-filter-select"
                    value={selectedWeek}
                    onChange={handleWeekFilterChange}
                >
                    <option value="">All Weeks</option>
                    {weekOptions.map(week => (
                        <option key={week} value={week}>{week}</option>
                    ))}
                </select>
                <label htmlFor="view-by-select">View By: </label>
                <select id="view-by-select" value={viewBy} onChange={handleViewByChange}>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                </select>
            </div>

            {loading ? (
                <p>Loading meat orders...</p>
            ) : (
                <>
                    <div className="statistics">
                        <h3>Total Spending: ${totalSpending.toFixed(2)}</h3>
                        <h3>Average Spending per Order: ${averageSpending}</h3>
                        <h3>Total Orders: {totalOrders}</h3>
                    </div>

                    <div className="order-list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Description</th>
                                    <th>Image</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOrders.map(order => (
                                    <tr key={order.id}>
                                        <td>{new Date(order.timestamp.seconds * 1000).toLocaleDateString()}</td>
                                        <td>${order.amount.toFixed(2)}</td>
                                        <td>{order.description}</td>
                                        <td><a href={order.imageURL} target="_blank" rel="noopener noreferrer">View Image</a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
}

export default MeatOrderDashboard;
