import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase-config';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import './ShoppingListPage.css';
import { useNavigate } from 'react-router-dom';

function ShoppingListPage() {
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState({ itemName: '', quantity: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const [user] = useAuthState(auth);
    const [editingItem, setEditingItem] = useState(null);
    const [stockLocation, setStockLocation] = useState(null);
    const [stockItem, setStockItem] = useState(null);
    const [inStoreItems, setInStoreItems] = useState([]);
    const [inventoryItems, setInventoryItems] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const itemsCollectionRef = collection(db, 'shopping_list');
    const navigate = useNavigate();

    useEffect(() => {
        fetchItems();
        fetchInStoreItems();
        fetchInventoryItems();
    }, []);

    const fetchItems = async () => {
        const itemSnapshot = await getDocs(itemsCollectionRef);
        let fetchedItems = itemSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Sort items based on status order
        fetchedItems.sort((a, b) => {
            const statusOrder = { 'None': 0, 'Acknowledged': 1, 'Ordered': 2, 'Received': 3 };
            return statusOrder[a.status || 'None'] - statusOrder[b.status || 'None'];
        });

        setItems(fetchedItems);
    };

    const fetchInStoreItems = async () => {
        const inStoreSnapshot = await getDocs(collection(db, 'in-store'));
        setInStoreItems(inStoreSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const fetchInventoryItems = async () => {
        const inventorySnapshot = await getDocs(collection(db, 'items'));
        setInventoryItems(inventorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const handleAddItem = async (e) => {
        e.preventDefault();
        if (items.some(item => item.itemName.toLowerCase() === newItem.itemName.toLowerCase())) {
            alert('Item already in list');
            setNewItem({ itemName: '', quantity: '' });
            return;
        }
        if (newItem.itemName && newItem.quantity) {
            await addDoc(itemsCollectionRef, {
                ...newItem,
                addedBy: user.displayName || user.email,
                completed: false,
                status: 'None',
                stockLocation: 'None',
            });
            setNewItem({ itemName: '', quantity: '' });
            fetchItems();
        } else {
            alert('Please fill in all fields.');
        }
    };

    const handleDownload = () => {
        const fileContent = items.map(item => `${item.itemName} | ${item.quantity}`).join('\n');
        const blob = new Blob([fileContent], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'shopping_list.txt';
        link.click();
        URL.revokeObjectURL(url);  // Clean up URL object
    };

    const handleUpdateItem = async (id, updatedItem) => {
        const itemDoc = doc(db, 'shopping_list', id);
        await updateDoc(itemDoc, updatedItem);
        fetchItems();
    };

    const handleCheckboxChange = async (id, completed) => {
        const itemDoc = doc(db, 'shopping_list', id);
        await updateDoc(itemDoc, { completed });

        if (completed) {
            setItems(items.map(item => item.id === id ? { ...item, completed: true } : item));
            setTimeout(async () => {
                await deleteDoc(itemDoc);
                fetchItems();
            }, 5000);
        } else {
            fetchItems();
        }
    };

    const checkInStock = (itemName) => {
        if (!itemName) return 'None';

        const ignoredWords = ['for', 'and', 'or', 'halal'];
        const keywords = itemName
            .toLowerCase()
            .split(' ')
            .filter(word => !ignoredWords.includes(word) && word.length > 2);

        const matchWholeWord = (word, target) => {
            const regex = new RegExp(`\\b${word}\\b`, 'i');
            return regex.test(target);
        };

        const foundInStore = inStoreItems.find(item =>
            keywords.some(keyword => matchWholeWord(keyword, item.Name))
        );

        const foundInInventory = inventoryItems.find(item =>
            keywords.some(keyword => matchWholeWord(keyword, item.Name))
        );

        if (foundInStore) return 'In Store';
        if (foundInInventory) return 'In Storage';
        return 'Not in Stock';
    };

    const handleStockClick = (item) => {
        const location = checkInStock(item.itemName);
        setStockLocation(location);
        setStockItem(item);
    };

    const handleMarkAsNone = async () => {
        if (stockItem) {
            await updateDoc(doc(db, 'shopping_list', stockItem.id), {
                stockLocation: 'None'
            });
            const updatedItems = items.map(i =>
                i.id === stockItem.id ? { ...i, stockLocation: 'None' } : i
            );
            setItems(updatedItems);
            setStockLocation('None');
            setStockItem(null);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleItemNameChange = (e) => {
        const value = e.target.value;
        setNewItem({ ...newItem, itemName: value });

        if (value.length > 2) {
            const matches = items.filter(item =>
                item.itemName.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(matches);
        } else {
            setSuggestions([]);
        }
    };

    const handleComputeShoppingList = () => {
        navigate('/computed-shopping-list');
    };

    const selectSuggestion = (suggestion) => {
        setNewItem({ itemName: suggestion.itemName, quantity: suggestion.quantity });
        setSuggestions([]);
    };

    const filteredItems = items.filter(item =>
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="shopping-list-container">
            <h1>Shopping List</h1>
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-bar"
            />
            <form onSubmit={handleAddItem} className="add-item-form">
                <div className="suggestion-dropdown">
                    <input
                        type="text"
                        placeholder="Item Name"
                        value={newItem.itemName}
                        onChange={handleItemNameChange}
                        required
                    />
                    {suggestions.length > 0 && (
                        <div className="suggestion-list">
                            {suggestions.map((suggestion, index) => (
                                <div
                                    key={index}
                                    className="suggestion-item"
                                    onClick={() => selectSuggestion(suggestion)}
                                >
                                    {suggestion.itemName}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <input
                    type="text"
                    placeholder="Quantity"
                    value={newItem.quantity}
                    onChange={(e) => setNewItem({ ...newItem, quantity: e.target.value })}
                    required
                />
                <button type="submit">Add Item</button>
            </form>

            <button onClick={handleDownload} className="download-button">
                Download Shopping List
            </button>

            <button
                className="compute-shopping-list-button"
                onClick={handleComputeShoppingList}
            >
                Compute Shopping List
            </button>

            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Done</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            <th>Status</th>
                            <th className="desktop-only">Added By</th>
                            <th>In-Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredItems.map(item => (
                            <tr key={item.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={item.completed}
                                        onChange={() => handleCheckboxChange(item.id, !item.completed)}
                                    />
                                </td>
                                <td onClick={() => setEditingItem(item)}>
                                    {item.itemName}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={item.quantity}
                                        onChange={(e) => handleUpdateItem(item.id, { ...item, quantity: e.target.value })}
                                    />
                                </td>
                                <td>
                                    <select
                                        className={`status-select status-${(item.status || 'None').toLowerCase()}`}
                                        value={item.status || 'None'}
                                        onChange={(e) => handleUpdateItem(item.id, { ...item, status: e.target.value })}
                                    >
                                        <option value="None">None</option>
                                        <option value="Acknowledged">Acknowledged</option>
                                        <option value="Ordered">Ordered</option>
                                        <option value="Received">Received</option>
                                    </select>
                                </td>
                                <td className="desktop-only">{item.addedBy}</td>
                                <td>{checkInStock(item.itemName)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {editingItem && (
                <div className="popup">
                    <div className="popup-inner">
                        <h2>Edit Item</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleUpdateItem(editingItem.id, editingItem);
                            setEditingItem(null);
                        }}>
                            <input
                                type="text"
                                value={editingItem.itemName}
                                onChange={(e) => setEditingItem({ ...editingItem, itemName: e.target.value })}
                                required
                            />
                            <input
                                type="text"
                                value={editingItem.quantity}
                                onChange={(e) => setEditingItem({ ...editingItem, quantity: e.target.value })}
                                required
                            />
                            <button type="submit">Save</button>
                            <button type="button" onClick={() => setEditingItem(null)}>Cancel</button>
                            <button type="button" onClick={async () => {
                                await deleteDoc(doc(db, 'shopping_list', editingItem.id));
                                setEditingItem(null);
                                fetchItems();
                            }} className="delete-button">Delete</button>
                        </form>
                    </div>
                </div>
            )}

            {stockLocation && (
                <div className="popup">
                    <div className="popup-inner">
                        <h2>Stock Location</h2>
                        <p>{stockLocation}</p>
                        <button type="button" onClick={() => setStockLocation(null)}>Close</button>
                        <button type="button" onClick={handleMarkAsNone}>Mark as None</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ShoppingListPage;
