import React, { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from './firebase-config'; // Ensure the path is correct
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Login.css'; // Ensure the CSS file is correctly linked

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(''); // Define an error state
    const navigate = useNavigate(); // Hook for redirection

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(''); // Reset error on new submission

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/'); // Redirect to main page on successful login
        } catch (error) {
            console.error("Authentication error:", error);
            setError("Failed to log in. Please check your credentials."); // Set error message
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <h2>Login</h2>
                {error && <p className="error">{error}</p>} {/* Display error message if there is an error */}
                <div className="input-group">
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="button">Log In</button>
            </form>
        </div>
    );
}
