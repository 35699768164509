import React, { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from "firebase/firestore";
import './InventoryPage.css';

function InventoryPage() {
    const [items, setItems] = useState([]);
    const [search, setSearch] = useState('');
    const [newItem, setNewItem] = useState({ Name: '', Quantity: '', Description: '' });
    const [editingItem, setEditingItem] = useState(null);

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        const itemsCollection = collection(db, "items");
        const itemSnapshot = await getDocs(itemsCollection);
        const itemList = itemSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItems(itemList);
    };

    const handleAddItem = async () => {
        if (newItem.Name && newItem.Quantity && newItem.Description) {
            await addDoc(collection(db, "items"), {
                Name: newItem.Name,
                Quantity: parseInt(newItem.Quantity),
                Description: newItem.Description
            });
            setNewItem({ Name: '', Quantity: '', Description: '' });
            fetchItems();
        } else {
            alert("Please fill in all fields.");
        }
    };

    const updateQuantity = async (id, newQuantity) => {
        if (newQuantity >= 0) {
            const itemRef = doc(db, "items", id);
            await updateDoc(itemRef, { Quantity: newQuantity });
            fetchItems();
        }
    };

    const handleMove = async (id) => {
        try {
            const itemRef = doc(db, "items", id);
            const itemDoc = await getDoc(itemRef);

            if (!itemDoc.exists()) {
                throw new Error("Item not found in the 'items' collection.");
            }

            const itemData = itemDoc.data();
            await addDoc(collection(db, "in-store"), itemData);
            await deleteDoc(itemRef);
            fetchItems();

        } catch (error) {
            console.error("Error moving item:", error);
            alert("An error occurred while moving the item. Please try again.");
        }
    };

    const handleEdit = (item) => {
        setEditingItem(item);
    };

    const handleSaveEdit = async () => {
        if (editingItem) {
            const itemRef = doc(db, "items", editingItem.id);
            await updateDoc(itemRef, {
                Name: editingItem.Name,
                Description: editingItem.Description
            });
            setEditingItem(null);
            fetchItems();
        }
    };

    const handleDelete = async () => {
        if (editingItem && window.confirm("Are you sure you want to delete this item?")) {
            await deleteDoc(doc(db, "items", editingItem.id));
            setEditingItem(null);
            fetchItems();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewItem(prev => ({ ...prev, [name]: value }));
    };

    return (
        <div className="inventory-container">
            <h1>Storage Inventory List</h1>
            <div className="controls">
                <input 
                    type="text"
                    placeholder="Search by name..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="search-bar"
                />
                <div className="add-item-form">
                    <input
                        type="text"
                        name="Name"
                        value={newItem.Name}
                        placeholder="Name"
                        onChange={handleChange}
                    />
                    <input
                        type="number"
                        name="Quantity"
                        value={newItem.Quantity}
                        placeholder="Quantity"
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="Description"
                        value={newItem.Description}
                        placeholder="Description"
                        onChange={handleChange}
                    />
                    <button onClick={handleAddItem}>Add Item</button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Description</th>
                        <th>Move</th>
                    </tr>
                </thead>
                <tbody>
                    {items.filter(item => item.Name.toLowerCase().includes(search.toLowerCase())).map(item => (
                        <tr key={item.id}>
                            <td onClick={() => handleEdit(item)}>{item.Name}</td>
                            <td>
                                <div className="quantity-controls">
                                    <button onClick={() => updateQuantity(item.id, item.Quantity - 1)}>-</button>
                                    <input type="text" value={item.Quantity} onChange={(e) => updateQuantity(item.id, parseInt(e.target.value) || 0)} className="quantity-input"/>
                                    <button onClick={() => updateQuantity(item.id, item.Quantity + 1)}>+</button>
                                </div>
                            </td>
                            <td>{item.Description}</td>
                            <td>
                                <button onClick={() => handleMove(item.id)} className="move-button">
                                    Move
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {editingItem && (
                <>
                    <div className="popup-overlay" onClick={() => setEditingItem(null)}></div>
                    <div className="popup">
                        <h2>Edit Item</h2>
                        <input 
                            type="text" 
                            value={editingItem.Name}
                            onChange={(e) => setEditingItem({ ...editingItem, Name: e.target.value })} 
                        />
                        <input 
                            type="text" 
                            value={editingItem.Description}
                            onChange={(e) => setEditingItem({ ...editingItem, Description: e.target.value })} 
                        />
                        <button onClick={handleSaveEdit}>Save</button>
                        <button onClick={() => setEditingItem(null)}>Cancel</button>
                        <button onClick={handleDelete} className="delete-button">Delete</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default InventoryPage;
