import React, { useState, useEffect, useCallback } from 'react';
import { db } from './firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import './ReceiptDashboard.css';

function ReceiptDashboard() {
    const [receipts, setReceipts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState('week'); // Default filter
    const [filteredReceipts, setFilteredReceipts] = useState([]);
    const [uniqueEmployees, setUniqueEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');

    const fetchReceipts = useCallback(async () => {
        try {
            const receiptsCollection = collection(db, 'receipts');
            const receiptsSnapshot = await getDocs(receiptsCollection);
            const receiptData = receiptsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            const employees = [...new Set(receiptData.map(receipt => receipt.user))];
            setUniqueEmployees(employees);

            setReceipts(receiptData);
            applyFilter(receiptData, selectedFilter, selectedEmployee);
        } catch (error) {
            console.error("Error fetching receipts:", error);
        } finally {
            setLoading(false);
        }
    }, [selectedFilter, selectedEmployee]);

    useEffect(() => {
        fetchReceipts();
    }, [fetchReceipts]);

    const applyFilter = (receiptData, filterType, employee) => {
        // Exclude 'Restaurant Meat' receipts
        let filtered = receiptData.filter(receipt => receipt.transactionType !== 'Restaurant Meat');
        const now = new Date();

        if (filterType === 'week') {
            const weekStart = new Date(now.setDate(now.getDate() - now.getDay()));
            filtered = filtered.filter(receipt => new Date(receipt.timestamp.seconds * 1000) >= weekStart);
        } else if (filterType === 'month') {
            const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
            filtered = filtered.filter(receipt => new Date(receipt.timestamp.seconds * 1000) >= monthStart);
        } else if (filterType === 'employee' && employee) {
            filtered = filtered.filter(receipt => receipt.user === employee);
        } 

        setFilteredReceipts(filtered);
    };

    const filterReceipts = (filterType) => {
        setSelectedFilter(filterType);
        if (filterType !== 'employee') {
            setSelectedEmployee(''); // Reset selected employee when not filtering by employee
            applyFilter(receipts, filterType, '');
        } else {
            applyFilter(receipts, filterType, selectedEmployee);
        }
    };

    const handleEmployeeChange = (e) => {
        const employee = e.target.value;
        setSelectedEmployee(employee);
        applyFilter(receipts, 'employee', employee);
        setSelectedFilter('employee'); // Ensure selected filter is set to employee
    };

    return (
        <div className="receipt-dashboard-container">
            <h2>Receipt Dashboard</h2>

            <div className="filters">
                <button onClick={() => filterReceipts('week')} className={selectedFilter === 'week' ? 'active' : ''}>This Week</button>
                <button onClick={() => filterReceipts('month')} className={selectedFilter === 'month' ? 'active' : ''}>This Month</button>
                <button onClick={() => filterReceipts('all')} className={selectedFilter === 'all' ? 'active' : ''}>All Time</button>
                <div className="employee-filter">
                    <label htmlFor="employee-select">By Employee: </label>
                    <select id="employee-select" value={selectedEmployee} onChange={handleEmployeeChange}>
                        <option value="">Select Employee</option>
                        {uniqueEmployees.map((employee, index) => (
                            <option key={index} value={employee}>{employee}</option>
                        ))}
                    </select>
                </div>
            </div>

            {loading ? (
                <p>Loading receipts...</p>
            ) : (
                <>
                    <div className="statistics">
                        <h3>Total Spending: ${filteredReceipts.reduce((sum, receipt) => sum + receipt.amount, 0).toFixed(2)}</h3>
                        {/* Display other statistics */}
                    </div>

                    <div className="receipt-list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>User</th>
                                    <th>Amount</th>
                                    <th>Payment Type</th>
                                    <th>Reimbursement</th>
                                    <th>Description</th>
                                    <th>Image</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredReceipts.map(receipt => (
                                    <tr key={receipt.id}>
                                        <td>{new Date(receipt.timestamp.seconds * 1000).toLocaleDateString()}</td>
                                        <td>{receipt.user}</td>
                                        <td>${receipt.amount.toFixed(2)}</td>
                                        <td>{receipt.transactionType}</td>
                                        <td>{receipt.reimbursement ? 'Yes' : 'No'}</td>
                                        <td>{receipt.description}</td>
                                        <td><a href={receipt.imageURL} target="_blank" rel="noopener noreferrer">View Image</a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
}

export default ReceiptDashboard;
