import React from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import './Header.css';

function Header({ role }) {
  const auth = getAuth();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('User signed out.');
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  return (
    <header className="header">
      <Link to="/" className="logo">H2Fresh</Link>
      <div className="header-right">
        <Link to="/">Home</Link>
        <Link to="/storage">Storage</Link>
        <Link to="/in-store">In-Store</Link>
        <Link to="/submit-receipt">Upload Receipt</Link>
        <Link to="/receipt-dashboard">Receipt Dashboard</Link>
        <Link to="/shopping-list">Shopping List</Link>
        <Link to="/meat-market">Meat Market</Link>
        <Link to="/rest-meat-dashboard">Restaurant Meat Dashboard</Link>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
    </header>
  );
}

export default Header;
