import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase-config';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import './MeatMarketPage.css';

function MeatMarketPage() {
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState({ itemName: '', quantity: '', status: 'None', employee: '', dateAdded: '' });
    const [search, setSearch] = useState('');
    const [editingItem, setEditingItem] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [user] = useAuthState(auth);
    const itemsCollectionRef = collection(db, 'meat_market');

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        const itemSnapshot = await getDocs(itemsCollectionRef);
        const itemList = itemSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItems(itemList);
    };

    const handleAddItem = async (e) => {
        e.preventDefault();
        if (items.some(item => item.itemName.toLowerCase() === newItem.itemName.toLowerCase())) {
            alert('Item already in list');
            setNewItem({ itemName: '', quantity: '', status: 'None', employee: '', dateAdded: '' });
            return;
        }
        if (newItem.itemName && newItem.quantity) {
            await addDoc(itemsCollectionRef, {
                ...newItem,
                employee: user.email,
                dateAdded: new Date().toISOString()
            });
            setNewItem({ itemName: '', quantity: '', status: 'None', employee: '', dateAdded: '' });
            fetchItems();
        } else {
            alert('Please fill in all fields.');
        }
    };

    const handleUpdateItem = async (id, updatedItem) => {
        const itemDoc = doc(db, 'meat_market', id);
        await updateDoc(itemDoc, updatedItem);
        fetchItems();
    };

    const handleDeleteItem = async (id) => {
        const itemDoc = doc(db, 'meat_market', id);
        await deleteDoc(itemDoc);
        setEditingItem(null);
        fetchItems();
    };

    const handleItemNameChange = (e) => {
        const value = e.target.value;
        setNewItem({ ...newItem, itemName: value });

        if (value.length > 2) {
            const matches = items.filter(item =>
                item.itemName.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(matches);
        } else {
            setSuggestions([]);
        }
    };

    const selectSuggestion = (suggestion) => {
        setNewItem({ itemName: suggestion.itemName, quantity: suggestion.quantity });
        setSuggestions([]);
    };

    return (
        <div className="meat-market-container">
            <h1>Meat Market Shopping List</h1>
            <div className="top-controls">
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="search-bar"
                />
                <form onSubmit={handleAddItem} className="add-item-form">
                    <div className="suggestion-dropdown">
                        <input
                            type="text"
                            name="itemName"
                            value={newItem.itemName}
                            placeholder="Item Name"
                            onChange={handleItemNameChange}
                            required
                        />
                        {suggestions.length > 0 && (
                            <div className="suggestion-list">
                                {suggestions.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className="suggestion-item"
                                        onClick={() => selectSuggestion(suggestion)}
                                    >
                                        {suggestion.itemName}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <input
                        type="text"
                        name="quantity"
                        value={newItem.quantity}
                        placeholder="Quantity"
                        onChange={(e) => setNewItem({ ...newItem, quantity: e.target.value })}
                        required
                    />
                    <button type="submit">Add Item</button>
                </form>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Status</th>
                        <th className="desktop-only">Employee</th>
                        <th className="desktop-only">Date Added</th>
                    </tr>
                </thead>
                <tbody>
                    {items.filter(item => item.itemName.toLowerCase().includes(search.toLowerCase())).map(item => (
                        <tr key={item.id}>
                            <td onClick={() => setEditingItem(item)}>{item.itemName}</td>
                            <td onClick={() => setEditingItem(item)}>{item.quantity}</td>
                            <td>
                            <select
                                className={`status-select status-${(item.status || 'none').toLowerCase()}`}
                                value={item.status}
                                onChange={(e) => handleUpdateItem(item.id, { ...item, status: e.target.value })}
                            >
                                <option value="None" className="status-none">None</option>
                                <option value="Acknowledged" className="status-acknowledged">Acknowledged</option>
                                <option value="Ordered" className="status-ordered">Ordered</option>
                                <option value="Received" className="status-received">Received</option>
                                <option value="Voided" className="status-void">Voided</option>
                            </select>

                            </td>
                            <td className="desktop-only">{item.employee}</td>
                            <td className="desktop-only">{new Date(item.dateAdded).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {editingItem && (
                <div className="popup-overlay">
                    <div className="popup">
                        <h3>Edit Item</h3>
                        <input
                            type="text"
                            value={editingItem.itemName}
                            onChange={(e) => setEditingItem({ ...editingItem, itemName: e.target.value })}
                        />
                        <input
                            type="text"
                            value={editingItem.quantity}
                            onChange={(e) => setEditingItem({ ...editingItem, quantity: e.target.value })}
                        />
                        <button onClick={() => handleUpdateItem(editingItem.id, editingItem)}>Save</button>
                        <button onClick={() => setEditingItem(null)}>Cancel</button>
                        <button onClick={() => handleDeleteItem(editingItem.id)} className="delete-button">Delete</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MeatMarketPage;
