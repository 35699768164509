// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.receipt-dashboard-container {
    padding: 20px;
    background-color: #f4f4f4;
    min-height: 100vh;
}

h2 {
    color: #333;
    text-align: center;
}

.filters {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.filters button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #76a245;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.filters button.active,
.filters button:hover {
    background-color: #5a8d3d;
}

.statistics {
    text-align: center;
    margin-bottom: 20px;
}

.receipt-list {
    overflow-x: auto;
}

.receipt-list table {
    width: 100%;
    border-collapse: collapse;
}

.receipt-list th,
.receipt-list td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.receipt-list th {
    background-color: #76a245;
    color: white;
}

.receipt-list tr:nth-child(even) {
    background-color: #f9f9f9;
}

.receipt-list tr:hover {
    background-color: #f1f1f1;
}
`, "",{"version":3,"sources":["webpack://./src/ReceiptDashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;;IAEI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".receipt-dashboard-container {\n    padding: 20px;\n    background-color: #f4f4f4;\n    min-height: 100vh;\n}\n\nh2 {\n    color: #333;\n    text-align: center;\n}\n\n.filters {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 20px;\n}\n\n.filters button {\n    margin: 0 10px;\n    padding: 10px 20px;\n    background-color: #76a245;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.filters button.active,\n.filters button:hover {\n    background-color: #5a8d3d;\n}\n\n.statistics {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.receipt-list {\n    overflow-x: auto;\n}\n\n.receipt-list table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.receipt-list th,\n.receipt-list td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n}\n\n.receipt-list th {\n    background-color: #76a245;\n    color: white;\n}\n\n.receipt-list tr:nth-child(even) {\n    background-color: #f9f9f9;\n}\n\n.receipt-list tr:hover {\n    background-color: #f1f1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
