import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getIdTokenResult } from 'firebase/auth';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCH84NUEv3op6qWJQTZ4obmQrptIIjoQBs",
  authDomain: "h2fresh.firebaseapp.com",
  projectId: "h2fresh",
  storageBucket: "h2fresh.appspot.com",
  messagingSenderId: "981150706309",
  appId: "1:981150706309:web:12d14e722d191c346f17ec",
  measurementId: "G-QNH6D6RGXY"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, auth, storage, getIdTokenResult };